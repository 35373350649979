import { h, render, Fragment } from 'preact';
import { Popover, Transition } from '@headlessui/react';
import {
    ChartBarIcon,
    CursorClickIcon,
    DocumentReportIcon,
    MenuIcon,
    RefreshIcon,
    ShieldCheckIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import useAuth from 'js/components/User/useAuth.js';
import logo from 'img/Crane Finance Logo.png';

const solutions = [];
const resources = [];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Header() {
    const auth = useAuth();

    return (
        <Popover className="relative bg-gray-800 shadow-md font-inter">
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <a href="/">
                            <span className="sr-only">Crane Finance</span>
                            <img
                                className="h-14 w-auto"
                                src={logo}
                                alt="Crane Finance Logo"
                            />
                        </a>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <Popover.Button className="bg-logoblue-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-logoblue-100 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                    </div>
                    <Popover.Group
                        as="nav"
                        className="hidden md:flex md:space-x-5 lg:space-x-10"
                    >
                        <a
                            href="/rates-and-terms"
                            className="text-base font-medium text-white hover:text-blue-200"
                        >
                            Rates & Terms
                        </a>
                        <a
                            href="/faq"
                            className="text-base font-medium text-white hover:text-blue-200"
                        >
                            FAQ
                        </a>
                        <a
                            href="/contact-us"
                            className="text-base font-medium text-white hover:text-blue-200"
                        >
                            Contact
                        </a>
                    </Popover.Group>
                    <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        {(auth.loading || !auth.user) && (
                            <Fragment>
                                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                    <a
                                        href="/login"
                                        className="whitespace-nowrap text-base font-inter font-medium text-white hover:text-blue-200"
                                    >
                                        {' '}
                                        Sign in{' '}
                                    </a>
                                    <a
                                        href="/apply-now"
                                        className="ml-8 whitespace-nowrap inline-flex items-center justify-center font-inter px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white transition ease-in-out delay-120 bg-logoblue-100 hover:bg-white duration-300 hover:text-logoblue-100 hover:border-logoblue-100"
                                    >
                                        {' '}
                                        Apply Now{' '}
                                    </a>
                                </div>
                            </Fragment>
                        )}
                        {!auth.loading && auth.user && (
                            <Fragment>
                                <a
                                    href="/account"
                                    className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200 mr-2"
                                >
                                    My Account
                                </a>
                                |
                                <a
                                    onClick={() => {
                                        auth.sign_out(() => {
                                            window.location = '/';
                                        });
                                    }}
                                    className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200 ml-2"
                                >
                                    Logout
                                </a>
                            </Fragment>
                        )}
                    </div>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-gray-800 divide-y-2 divide-logoblue-100">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <a href="/">
                                            <div className="flex items-center">
                                                <img
                                                    className="h-10 w-auto"
                                                    src={logo}
                                                    alt="Crane Finance Logo"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-logoblue-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-logoblue-100 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    <a
                                        href="/rates-and-terms"
                                        className="text-base font-medium text-white hover:text-blue-200"
                                    >
                                        Rates & Terms
                                    </a>
                                    <a
                                        href="/faq"
                                        className="text-base font-medium text-white hover:text-blue-200"
                                    >
                                        FAQ
                                    </a>
                                    <a
                                        href="/contact-us"
                                        className="text-base font-medium text-white hover:text-blue-200"
                                    >
                                        Contact
                                    </a>
                                </div>
                                <div className="mt-6 flex justify-evenly">
                                    {(auth.loading || !auth.user) && (
                                        <Fragment>
                                            <a
                                                href="/login"
                                                className="whitespace-nowrap text-base font-medium text-white hover:text-blue-200"
                                            >
                                                Sign in
                                            </a>
                                            <a
                                                href="/apply-now"
                                                className="whitespace-nowrap text-base font-medium text-white hover:text-blue-200"
                                            >
                                                Apply Now
                                            </a>
                                        </Fragment>
                                    )}
                                    {!auth.loading && auth.user && (
                                        <Fragment>
                                            <a
                                                href="/account"
                                                className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200 mr-2"
                                            >
                                                My Account
                                            </a>
                                            |
                                            <a
                                                onClick={() => {
                                                    auth.sign_out(() => {
                                                        window.location = '/';
                                                    });
                                                }}
                                                className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200 ml-2"
                                            >
                                                Logout
                                            </a>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </div>
        </Popover>
    );
}
