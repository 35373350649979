function get_caret_position (ctrl) {
    // IE < 9 Support
    if (document.selection) {
        ctrl.focus();
        var range = document.selection.createRange();
        var rangelen = range.text.length;
        range.moveStart ('character', -ctrl.value.length);
        var start = range.text.length - rangelen;
        return {'start': start, 'end': start + rangelen };
    }
    // IE >=9 and other browsers
    else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
        return {'start': ctrl.selectionStart, 'end': ctrl.selectionEnd };
    } else {
        return {'start': 0, 'end': 0};
    }
}

function set_caret_position(ctrl, start, end) {
    // IE >= 9 and other browsers
    if(ctrl.setSelectionRange)
    {
        ctrl.focus();
        ctrl.setSelectionRange(start, end);
    }
    // IE < 9
    else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', start);
        range.select();
    }
}

function format_cc_number(entity, keycode = null) {
    let val = entity.value;
    let initial_length = val.length;
    let position = get_caret_position(entity);
    val = val.replace(/\D/g, '')
    let formatted = val.substr(0, 4);
    if (val.length >= 4) {
        formatted += ' ';
        formatted += val.substr(4, 4);
    }
    if (val.length >= 8) {
        formatted += ' ';
        formatted += val.substr(8, 4);
    }
    if (val.length >= 12) {
        formatted += ' ';
        formatted += val.substr(12, 4);
    }
    val = formatted;
    if (keycode == "Backspace") {
        position.start += (val.length - initial_length) - 1;
        position.end += (val.length - initial_length) - 1;
    } else if (keycode == "ArrowLeft" || keycode == "ArrowRight" ||
              keycode == "ArrowDown" || keycode == "ArrowUp") {
        position.start += (val.length - initial_length);
        position.end += (val.length - initial_length);
    } else {
        position.start += (val.length - initial_length) + 1;
        position.end += (val.length - initial_length) + 1;
    }
    entity.value = val;
    set_caret_position(entity, position.start, position.end);
}

function format_phone(entity) {
    var entity_val = entity.value;
    var cleaned_val = entity_val.replace(/\D/g, "");
    var formatted_val = "";
    var position = get_caret_position(entity);
    // Lets not add any dashes if they haven't gotten past 3 numbers
    if (cleaned_val.length <= 3) {
        entity.value = cleaned_val;
        set_caret_position(entity, position.start, position.end);
        return;
    }
    // Add our first dash
    if (cleaned_val.length <= 6) {
        formatted_val += '(' + cleaned_val.substr(0, 3) + ')';
        formatted_val += cleaned_val.substr(3, 3);
        if (formatted_val.length > entity_val.length) {
            position.start += (formatted_val.length - entity_val.length);
            position.end += (formatted_val.length - entity_val.length);
        }
        entity.value = formatted_val;
        set_caret_position(entity, position.start, position.end);
        return;
    }
    // Now the full formatting + preventing more than 10 characters total.
    formatted_val += '(' + cleaned_val.substr(0, 3) + ')';
    formatted_val += cleaned_val.substr(3, 3) + '-';
    cleaned_val = cleaned_val.substr(6);
    formatted_val += cleaned_val.substr(0, 4);
    if (formatted_val.length > entity_val.length) {
        position.start += (formatted_val.length - entity_val.length);
        position.end += (formatted_val.length - entity_val.length);
    }
    entity.value = formatted_val;
    set_caret_position(entity, position.start, position.end);
}

function format_ssn(entity) {
    var entity_val = entity.value;
    var cleaned_val = entity_val.replace(/\D/g, "");
    var formatted_val = "";
    var position = get_caret_position(entity);
    if (cleaned_val.length <= 3) {
        entity.value = cleaned_val;
        set_caret_position(entity, position.start, position.end);
        return;
    }

    if (cleaned_val.length <= 5) {
        formatted_val +=
            cleaned_val.substr(0,3)
            + "-"
            + cleaned_val.substr(3,2);
        if (formatted_val.length > entity_val.length) {
            position.start += (formatted_val.length - entity_val.length);
            position.end += (formatted_val.length - entity_val.length);
        }
        entity.value = formatted_val;
        set_caret_position(entity, position.start, position.end);
        return;
    }

    formatted_val +=
        cleaned_val.substr(0,3)
        + "-"
        + cleaned_val.substr(3,2)
        + "-"
        + cleaned_val.substr(5, 4);
    if (formatted_val.length > entity_val.length) {
        position.start += (formatted_val.length - entity_val.length);
        position.end += (formatted_val.length - entity_val.length);
    }
    entity.value = formatted_val;
    set_caret_position(entity, position.start, position.end);
}

function json_to_urlencoded(element,key,list){
    var list = list || [];
    if(typeof(element)=='object'){
        for (var idx in element) {
            json_to_urlencoded(element[idx],key?key+'['+idx+']':idx,list);
        }
    } else {
        list.push(key+'='+encodeURIComponent(element));
    }
    return list.join('&');
}

function get_url_parameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export {
    get_caret_position,
    set_caret_position,
    format_cc_number,
    format_phone,
    format_ssn,
    json_to_urlencoded,
    get_url_parameter
};
