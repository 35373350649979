import { h, render, Fragment } from "preact";
import Hero from "js/components/design/home/Hero.js";
import Featured from "js/components/design/home/Featured.js";
import Application from "js/components/design/home/Borrow.js";
import Content from "js/components/design/home/Content.js";
import FAQ from "js/components/design/home/FAQs.js";
import CTA from "js/components/design/home/CTA.js";

export default function Home() {
    return (
        <Fragment>
        <Hero />
        <Featured />
        <Application />
        <Content />
        <FAQ />
        <CTA />
        </Fragment>
    );
}
