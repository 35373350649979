import { Route, Redirect } from 'react-router-dom';
import useAuth from 'js/components/User/useAuth.js';
import { h, render, Fragment } from 'preact';

const Loading = () => {
    return <h1>Loading...</h1>;
};

function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.loading ? (
                    <Loading />
                ) : auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
export default PrivateRoute;
