import { h, render, Fragment } from 'preact';
import { Popover, Transition } from '@headlessui/react';
import {
    ChartBarIcon,
    CursorClickIcon,
    DocumentReportIcon,
    MenuIcon,
    RefreshIcon,
    ShieldCheckIcon,
    ViewGridIcon,
    XIcon,
} from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import logo from 'img/Crane Finance Logo.png';
import tribe_seal from 'img/tribe-seal.png';
import privacy_policy from 'docs/Crane Finance Privacy Policy.pdf';
import license from 'docs/License.pdf';
import sms_terms from 'docs/SMS Terms of Use.pdf';

const navigation = {
    quick: [
        // {
        //     name: 'Apply Now',
        //     href: '/apply-now',
        // },
    ],
    resources: [
        {
            name: 'SMS Terms of Use',
            href: sms_terms,
        },
        {
            name: 'Privacy Policy',
            href: privacy_policy,
        },
        {
            name: 'Terms of Use',
            href: '/terms-of-use',
        },
        {
            name: 'Rates & Terms',
            href: '/rates-and-terms',
        },
        {
            name: 'Data Collection',
            href: '/data-collection',
        },
        {
            name: 'FAQ',
            href: '/faq',
        },
        {
            name: 'Lender License',
            href: license,
        },
    ],
};

export default function Footer() {
    return (
        <Popover className="relative shadow-md bg-gray-800 font-inter">
            <footer aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="/">
                                <span className="sr-only">Crane Finance</span>
                                <img
                                    className="h-14 w-auto"
                                    src={logo}
                                    alt="Crane Finance Logo"
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 md:hidden">
                            <Popover.Button className="bg-logoblue-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-logoblue-100 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <MenuIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Popover.Button>
                        </div>
                        <Popover.Group
                            as="nav"
                            className="hidden md:flex space-x-10"
                        >
                            {navigation.resources.map((item) => (
                                <div key={item.name}>
                                    <a
                                        href={item.href}
                                        className="text-base text-white hover:text-blue-200"
                                    >
                                        {item.name}
                                    </a>
                                </div>
                            ))}
                        </Popover.Group>
                    </div>
                </div>
                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-white ring-opacity-5 bg-gray-800 divide-y-2 divide-logoblue-100">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <a href="/">
                                            <div className="flex items-center">
                                                <img
                                                    className="h-10 w-auto"
                                                    src={logo}
                                                    alt="Crane Finance Logo"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-logoblue-100 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-logoblue-100 hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Close menu
                                            </span>
                                            <XIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="py-6 px-5">
                                <div className="grid grid-cols-2 gap-4">
                                    {navigation.resources.map((item) => (
                                        <div key={item.name}>
                                            <a
                                                href={item.href}
                                                className="text-base text-white hover:text-blue-200"
                                            >
                                                {item.name}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
                <div className="max-w-7xl mx-auto text-justify py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <p className="my-5 text-gray-400">
                        CraneFinance.com is a website owned and operated by
                        Crane Lending, LLC d/b/a Crane Finance ("Crane
                        Finance"), an economic development arm of,
                        instrumentality of, and a limited liability company
                        wholly­owned and controlled by, the Menominee Indian
                        Tribe of Wisconsin (the "Tribe"), a federally-recognized
                        sovereign American Indian tribe. Crane Finance adheres
                        to federal consumer finance laws and operates within the
                        boundaries of the Tribe's reservation. Funds borrowed
                        from Crane Finance are governed by federal law and the
                        laws of the Tribe, not the law of the state where you
                        reside. Nothing in this communication should be
                        construed as a waiver of sovereign immunity, which is
                        expressly reserved.
                    </p>
                    <p className="my-5 text-gray-400">
                        <span className="text-gray-300">Please note:</span>
                        This is an expensive form of borrowing. Crane Finance
                        installment loans are designed to assist you in meeting
                        your short-term financial needs and are not intended to
                        be a long-term financial solution. Examples of why our
                        services may be used include unexpected emergencies, car
                        repair bills, medical care or essential travel expenses.
                    </p>
                    <p className="my-5 text-gray-400">
                        Completion of our installment loan application in no way
                        guarantees that you will be approved for an installment
                        loan. Crane Finance reviews your information in
                        real-time to determine whether you meet our lending
                        criteria. You acknowledge that by submitting and
                        completing the loan application that you are applying
                        for a loan. We verify loan applicant information through
                        alternative credit reporting agencies and may obtain
                        your credit information in order to determine your
                        eligibility and ability to repay.
                    </p>
                    <p className="my-5 text-gray-400">
                        This service is offered to you from the Tribe’s
                        reservation, regardless of where you may be situated or
                        access this site, and constitutes an offer for consumer
                        loans solely within the Tribe’s jurisdiction, not any
                        other state. The Tribe does not allow access or accept
                        applications for this service from residents of all
                        locations, and the service may or may not be available
                        at your location. The location from which the Tribe
                        chooses to allow access to its jurisdiction may change
                        from time to time without notice. If you choose to visit
                        the Tribe’s jurisdiction through the internet and access
                        services offered and regulated under the Tribe’s laws,
                        you must consent to the Tribe’s jurisdiction and agree
                        that Tribal law governs such services. Availability of
                        loans are subject to change at any time at the sole
                        discretion of Crane Finance.
                    </p>
                    <p className="my-5 text-gray-400">
                        If you have additional questions, please call us at{' '}
                        <span className="text-gray-300">855.432.7654</span> or
                        email us at{' '}
                        <span className="text-gray-300">
                            CustomerService@CraneFinance.com
                        </span>
                        .
                    </p>
                    <div className="flex justify-center items-center">
                        <a
                            onClick={() =>
                                alert('This link will open a new window')
                            }
                            href="https://onlinelendersalliance.org/look-for-the-ola-seal"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="https://stage.ola-memberseal.org/seal?seal=member&token=4zguf7kfeMZlVSJcpShq" />
                        </a>
                    </div>
                    <div className="mt-8 border-t border-white-200 pt-8 md:flex md:items-center md:justify-between">
                        <p className="mt-8 text-base text-white md:mt-0 md:order-1">
                            &copy; 2023 Crane Finance. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </Popover>
    );
}
