import Auth from 'js/components/User/Auth.js';
import { h, render, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { json_to_urlencoded } from 'js/shared/form-helpers.js';

function useProvideAuth() {
    const [user, set_user] = useState(null);
    const [loading, set_loading] = useState(true);

    const sign_in = async (user, pass, captcha, cb) => {
        const r = await fetch('/api/form/get-csrf');
        const csrf = await r.json();
        fetch('/api/login/submit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: json_to_urlencoded({
                [csrf.token_name]: csrf.token_value,
                captcha: captcha,
                email: user,
                password: pass,
            }),
        })
            .then((r) => {
                return r.json();
            })
            .then((response) => {
                if (response.success) {
                    set_user(response.user);
                }
                cb(response);
            });
    };

    const sign_out = async (cb) => {
        await fetch('/api/login/logout');
        set_user(null);
        cb();
    };

    useEffect(() => {
        (async () => {
            const r = await fetch('/api/login/user-info');
            const response = await r.json();
            if (response.success) {
                set_user(response.user);
            } else {
                set_user(null);
            }
            set_loading(false);
        })();
    }, []);

    return {
        user,
        loading,
        sign_in,
        sign_out,
    };
}

const AuthProvider = ({ children }) => {
    const auth = useProvideAuth();
    return <Auth.Provider value={auth}>{children}</Auth.Provider>;
};

export default AuthProvider;
